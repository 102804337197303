import * as React from 'react'
import * as styles from './landing.module.css'
import { Link } from 'gatsby'
import { VscArrowRight } from 'react-icons/vsc'

function Landing(props) {

	return (
		<Link className={styles.landing} to='/stills'>
			<h1 className={styles.first}>Welcome to my studio,</h1>
			<h1 className={styles.second}>
				have a look around
				<VscArrowRight className={styles.ctaIcon} />
			</h1>
		</Link>
	)
}

export { Landing }