import * as React from "react"
import { Layout } from '../components/layout'
import { Landing } from "../components/landing"
import { Helmet } from 'react-helmet'

export default function IndexPage() {

  return (
    <Layout>
      <Helmet>
        <title>Bethany Rachelle</title>
      </Helmet>
      <Landing />
    </Layout>
  )
}
